<template>
  <div class="row">
    <div class="col-lg-7 m-auto">
      <h1>{{ t('verify_email') }}</h1>
        <form @submit.prevent="send" @keydown="form.onKeydown($event)">
          <div v-if="status" class="alert alert-success">{{ status }}</div>
          <!-- Email -->
          <div class="form-group">
            <div class="form-label">{{ t('email') }}</div>
            <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" >
            <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" class="invalid-feedback" />
          </div>
          <div class="mb-3 row">
            <div class="col-md-9 ms-md-auto">
              <button class="btn btn--blue" type="submit" :loading="form.busy">{{ t('send_verification_link') }}</button>
            </div>
          </div>
        </form>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'
import Form from 'vform'
import { onMounted } from 'vue'
export default {
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('verify_email')
    })
    const route = useRoute()
    const status = ref('')
    const form = ref(new Form({
      email: ''
    }))
    onMounted(() => {
      if (route.query.email) {
        form.value.email = route.query.email
      }
    })
    // methods
    async function send () {
      const { data } = await form.value.post(process.env.VUE_APP_API_URL + '/email/resend')
      status.value = data.status
      form.value.reset()
    }
    return {
      status,
      form,
      send
    }
  }
}
</script>
